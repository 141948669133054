import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard'),
    meta: {
      auth: true,
    },
  },
  // --------------- BRANDS ---------------
  {
    path: '/brands',
    name: 'Brands',
    component: () => import('@/views/Brands'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/addbrand',
    name: 'AddBrand',
    component: () => import('@/views/AddBrand'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/editbrand/:id',
    name: 'EditBrand',
    component: () => import('@/views/EditBrand'),
    meta: {
      auth: true,
    },
  },
  // -------------------SERVICES -----------------------
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/addservice',
    name: 'AddService',
    component: () => import('@/views/AddService'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/editservice/:id',
    name: 'EditService',
    component: () => import('@/views/EditService'),
    meta: {
      auth: true,
    },
  },
  // ---------------------CLIENTS---------------------------

  {
    path: '/clients',
    name: 'Clients',
    component: () => import('@/views/Clients'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/addclient',
    name: 'AddClient',
    component: () => import('@/views/AddClient'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/editclient/:id',
    name: 'EditClient',
    component: () => import('@/views/EditClient'),
    meta: {
      auth: true,
    },
  },
  // ---------------------CLIENTS---------------------------

  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/Events'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/addevent',
    name: 'AddEvent',
    component: () => import('@/views/AddEvent'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/editevent/:id',
    name: 'EditEvent',
    component: () => import('@/views/EditEvent'),
    meta: {
      auth: true,
    },
  },
  // ------------------------- NEWSLETTER -----------------------
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: () => import('@/views/Newsletter'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/addnewsletter',
    name: 'AddNewsletter',
    component: () => import('@/views/AddNewsletter'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/editnewsletter/:id',
    name: 'EditNewsletter',
    component: () => import('@/views/EditNewsletter'),
    meta: {
      auth: true,
    },
  },
  // ------------------------- JOBS -----------------------
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('@/views/Jobs'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/addjobs',
    name: 'AddJobs',
    component: () => import('@/views/AddJobs'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/editjobs/:id',
    name: 'EditJobs',
    component: () => import('@/views/EditJobs'),
    meta: {
      auth: true,
    },
  },
  // ------------------------- IMAGES -----------------------
  {
    path: '/banners',
    name: 'Banners',
    component: () => import('@/views/Banners'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/home-sliders',
    name: 'HomeSliders',
    component: () => import('@/views/HomeSliders'),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'link-active',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth === true) {
    if (store.state.token) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
