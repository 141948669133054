<template>
  <router-view />
</template>

<style>
table {
  margin-bottom: 0 !important;
}
.link-active {
  font-weight: bold;
  color: white;
}
</style>
