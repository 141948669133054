import { createStore } from 'vuex';
import axios from 'axios';
import router from '@/router';

export default createStore({
  state: {
    // Authentication
    token: localStorage.getItem('authToken') || '',
  },
  mutations: {
    // Authentication
    setToken(state, token) {
      localStorage.setItem('authToken', token);
      state.token = token;
    },
    removeToken(state) {
      localStorage.removeItem('authToken');
      state.token = '';
    },
  },
  actions: {
    logout({ commit }) {
      commit('removeToken');
      router.push('/');
    },
    async uploadImage({ commit, state }, file) {
      try {
        const fd = new FormData();
        fd.append('image', file);
        const res = await axios.post('/api/v1/upload', fd, {
          headers: {
            authorization: `bearer ${state.token}`,
          },
        });
        if (res.status == 200) {
          console.log(res.data);
          return res.data.filename;
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
  },
  modules: {},
});
